<template>
  <div class="announcementInfo">
    <h1>{{ infoData.title }}</h1>
    <p style="color: #99a2ac">{{ infoData.time | formatTime("all") }}</p>
    <!-- <div style="line-height: 30px" v-html="infoData.content"></div> -->
    <div>
      <Editor
        style="border-top: 1px solid #ccc"
        v-model="content"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
      />
    </div>
  </div>
</template>
<script>
import { Editor } from "@wangeditor/editor-for-vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      infoData: JSON.parse(localStorage.ARTICLEINFO),
      content: "",
      editor: null,
      editorConfig: {
        // 富文本菜单栏配置
        MENU_CONF: {
          "z-index": 99,
        },
        readOnly: true,
      },
      mode: "simple", // or 'simple'
    };
  },
  created() {
    this.$route.meta.bread[2].name = JSON.parse(localStorage.ARTICLEINFO).title;
  },
  mounted() {
    this.content = this.infoData.content;
    // this.editor.dangerouslyInsertHtml(this.infoData.content);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.editor.setHtml(this.infoData.content);
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less">
.announcementInfo {
  width: 80%;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  a {
    color: blue;
    text-decoration: underline;
  }

}
</style>